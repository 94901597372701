import WebServiceRequest from './WebServiceRequest'

class PageCreateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setMethod('POST')
        super.setTag('DynamicPages/CreateDynamicPage')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}

class PageUpdateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setMethod('PUT')
        super.setTag('DynamicPages/UpdateDynamicPage')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}

class PageGetByIdRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setMethod('GET')
        super.setTag('DynamicPages/GetDynamicPageBy')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}

class PageDeleteRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setMethod('DELETE')
        super.setTag('DynamicPages/DeleteDynamicPage')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}

class PageGetListRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setMethod('GET')
        super.setTag('DynamicPages/GetDynamicPages')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}

export {
    PageCreateRequest,
    PageUpdateRequest,
    PageGetByIdRequest,
    PageDeleteRequest,
    PageGetListRequest,
}
